import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import moment from "moment";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "sector",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      sectorId: null,
      valid: false,
      cityRequired: [true],
      shopsSearchInput: null,
      donationSelected: false,
      sector: {
        name: "",
        description: "",
        shops: [],
        cityId: "",
        image: "",
        imageContentType: "",
        imageFilePath: "",
        active: false,
        iconActivePngImage: undefined,
        iconNormalSvgImage: undefined,
        iconActiveSvgImage: undefined,
        donationBannerImage: undefined,
        start: null,
        end: null,
        url: "",
        sectorType: ""
      },
      shops: [],
      cities: [],
      shopsIsLoading: false,
      citiesIsLoading: false,
      types: ["CATEGORY_SECTOR", "DONATION"],
    };
  },
  created() {
    this.sectorId = this.$route.params.sectorId;
    let title = this.sectorId != null ? "Sector details" : "Insert new sector";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.sectorId != null) {
      ApiService.get(`api/sectors`, `${this.sectorId}`)
        .then(response => {
          this.$log.debug("Sector: ", response.data);
          this.sector = response.data;
          this.sector.shopIds = response.data.shops.map(function(shop) {
            return shop.id;
          });
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchCity();
    this.searchShops();
  },
  methods: {
    validate: function () {
      this.$log.debug("CATEGORY_SECTOR: ", this.sector.sectorType);
      this.$log.debug("this.sector.cityId: ", this.sector.cityId);
      if (this.sector.sectorType === "CATEGORY_SECTOR" && this.sector.cityId < 1){
        this.cityRequired = ["City is required for CATEGORY SECTOR type!"];
      } else {
        this.cityRequired = [true];
      }
    },
    handlePngActive(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.sector.iconActivePngImage = base64Data;
        });
      }
    },
    handleSvgNormal(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.sector.iconNormalSvgImage = base64Data;
        });
      }
    },
    handleSvgActive(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.sector.iconActiveSvgImage = base64Data;
        });
      }
    },
    handleDonationBanner(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.sector.donationBannerImage = base64Data;
        });
      }
    },
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    setFileData(event, entity, field, isImage) {
      this.$log.info("event: ", event);
      this.$log.info("event.target: ", event.target);
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (isImage && !/^image\//.test(file.type)) {
          return;
        }
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          entity[field] = base64Data;
          entity[`${field}ContentType`] = file.type;
        });
      }
    },
    createBase64Image(fileObject) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        this.sector.image = e.target.result;
      };
      fileReader.readAsDataURL(fileObject);
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result.substr(
          e.target.result.indexOf("base64,") + "base64,".length
        );
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    clearInputImage(field, fieldContentType) {
      if (this.sector && field && fieldContentType) {
        if (Object.prototype.hasOwnProperty.call(this.sector, field)) {
          this.sector[field] = null;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.sector, fieldContentType)
        ) {
          this.sector[fieldContentType] = null;
        }
      }
    },
    remove(item) {
      const index = this.sector.shopIds.indexOf(item.id);
      if (index >= 0) this.sector.shopIds.splice(index, 1);
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsIsLoading = false));
    },
    searchCity() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveSector(this.sector);
      }
    },
    saveSector(sector) {
      this.loading = true;
      this.isSaving = true;

      this.$log.debug("Sector id to be created: " + sector.id);
      this.$log.debug("Sector name to be created: " + sector.name);
      // sector.cityId = this.selectedCityLocal;
      // sector.shopIds = this.selectedShopsLocal;
      this.$log.debug("shops to be saved: " + this.selectedShopsLocal);
      this.$log.debug("cities to be saved: " + this.selectedCityLocal);
      ApiService.post(`api/sectors`, sector)
        .then(response => {
          this.$log.debug("Sector created: " + response);
          this.$emit("sectorSaved", "sectorSaved");
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.isSaving = false;
          this.previousState();
        });
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    disableDonationFields() {
      // evaluate whatever you need to determine disabled here...
      return this.sector.sectorType !== "DONATION";
    },
    disableCategorySectorFields() {
      // evaluate whatever you need to determine disabled here...
      return this.sector.sectorType !== "CATEGORY_SECTOR";
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    },
    dateStartFormatted: {
      get() {
        if (this.sector.start == null || this.sector.start === "")
          return null;
        return moment
            .utc(this.sector.start)
            .local()
            .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.sector.start = value;
      }
    },
    dateEndFormatted: {
      get() {
        if (this.sector.end == null || this.sector.start === "")
          return null;
        return moment
            .utc(this.sector.end)
            .local()
            .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.sector.end = value;
      }
    }
  }
};
